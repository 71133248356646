<template>
  <div>
    <a-card title="采购记录">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="单号,供应商编号/名称" allowClear @search="search" />
        </a-col>
        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-range-picker @change="onChangePicker" />
        </a-col> -->

        <a-col :span="8">
          <a-form-model-item label="自定义时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-range-picker v-model="searchForm.dateRange" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-select v-model="searchForm.is_finished_instock" placeholder="是否完成入库" allowClear style="width: 100%;" @change="search">
            <a-select-option :value="false">未完成</a-select-option>
            <a-select-option :value="true">已完成</a-select-option>
          </a-select>
        </a-col>

      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <a slot="instocknumber" slot-scope="text">{{ instocknumber }}</a>
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
              </a-popconfirm>
              <!--<a-popconfirm title="确定拷贝吗?" @confirm="reCreateItem(item)">-->
                <a-button type="danger" :disabled="!(item.is_void)"  @click="reCreateItem(item)">{{"拷贝"}}</a-button>
              <!--</a-popconfirm>-->
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { purchaseOrderList, purchaseOrdersVoid } from '@/api/purchasing'

  export default {
    name: 'Warehouse',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '采购编号',
            dataIndex: 'number',
            sorter: true,
            width: 120,
          },
          {
            title: '供应商',
            dataIndex: 'supplier_name',
            width: 140,
          },

          {
            title: '采购产品',
            customRender: (value, item, index) => {
              let products = ""
              for (let i in item.purchase_goods_items){
                  products = products + item.purchase_goods_items[i].goods_name + ','
              }
              return products.substring(0,20)
            },
            width: 160,
          },

          {
            title: '经手人',
            dataIndex: 'handler_name',
            width: 80,
          },
          {
            title: '处理日期',
            dataIndex: 'handle_time',
            width: 100
          },
          {
            title: '预计到货日期',
            dataIndex: 'expected_arrival_date',
            width: 100
          },
          {
            title: '采购总数量',
            dataIndex: 'total_quantity',
            width: 80
          },
          {
            title: '采购总金额',
            dataIndex: 'total_amount',
            width: 80
          },
          {
            title: '对应入库单',
            dataIndex: 'instocknumber',
            width: 120
          },
          {
            title: '已完成入库',
            dataIndex: 'is_completed',
            width: 80
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: { page: 1, pageSize: 20 },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        this.items = [];
        purchaseOrderList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          let temp = data.results;
          for (let item in temp){
              temp[item].instocknumber = temp[item].in_stock_order.number;
              if (temp[item].in_stock_order.is_completed){
                  temp[item].is_completed = '入库完成';
              }else if (temp[item].in_stock_order.remain_quantity < temp[item].in_stock_order.total_quantity){
                  temp[item].is_completed = '部分入库';
              } else{
                  temp[item].is_completed = '未入库';
              }
              this.items.push(temp[item]);
          }
          //this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        let pi = {"pagename": "purchaseRecord", "searchForm": this.searchForm, "pagination": this.pagination}
        this.$store.commit('setPageInfo', pi);
        this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      },
      voidItem(item) {
        purchaseOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },

      reCreateItem(item) {
          this.$router.push({ path: '/purchasing/purchase_recreate', query: { id: item.id } });
      },
    },
    mounted() {
      if (this.$store.state.pageinfo.effect && this.$store.state.pageinfo.pagename == "purchaseRecord") {
          this.searchForm = this.$store.state.pageinfo.searchForm;
          this.pagination = this.$store.state.pageinfo.pagination;
          this.$store.commit('setEffect', false);
      }else {
          this.$store.commit('setEffect', false);
      }
      this.initialize();
    },
  }
</script>